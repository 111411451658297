import * as React from "react"

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <defs>
      <radialGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        cy={84.979}
        cx={86.396}
        gradientTransform="translate(-125.18 -123.13) scale(2.4489)"
        r={110.47}
      >
        <stop
          style={{
            stopColor: "#252525",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#121212",
          }}
          offset={0.264}
        />
        <stop
          style={{
            stopColor: "#000",
          }}
          offset={1}
        />
      </radialGradient>
      <linearGradient
        id="b"
        y2={352.63}
        gradientUnits="userSpaceOnUse"
        x2={124.99}
        y1={14.871}
        x1={124.99}
      >
        <stop
          style={{
            stopColor: "#fff",
          }}
          offset={0}
        />
        <stop
          style={{
            stopColor: "#ccc",
          }}
          offset={1}
        />
      </linearGradient>
    </defs>
    <path
      style={{
        fillRule: "evenodd",
        fill: "url(#a)",
      }}
      d="M235.46 125.34a110.47 110.47 0 1 1-220.95 0 110.47 110.47 0 1 1 220.95 0z"
      transform="translate(4.277 -.96) scale(1.001)"
    />
    <path
      style={{
        fillRule: "evenodd",
        fill: "url(#b)",
      }}
      d="M235.46 125.34a110.47 110.47 0 1 1-220.95 0 110.47 110.47 0 1 1 220.95 0z"
      transform="translate(34.106 34.73) scale(.48447)"
    />
    <path
      style={{
        fill: "#000",
      }}
      d="M83.148 885.96c-3.424-1.253-5.962-3.042-7.612-5.365-1.651-2.324-2.477-5.106-2.477-8.347 0-4.891 1.758-9.003 5.274-12.336 3.516-3.332 8.193-4.998 14.033-4.998 5.87 0 10.593 1.704 14.17 5.113 3.577 3.409 5.365 7.56 5.365 12.45 0 3.119-.817 5.832-2.453 8.14s-4.12 4.089-7.452 5.342c4.127 1.346 7.269 3.516 9.424 6.512 2.155 2.996 3.233 6.573 3.233 10.731 0 5.748-2.033 10.578-6.1 14.491-4.065 3.913-9.415 5.87-16.05 5.87-6.633 0-11.983-1.964-16.05-5.893-4.065-3.928-6.098-8.827-6.098-14.697 0-4.372 1.108-8.033 3.324-10.983s5.373-4.96 9.47-6.03zm-1.65-13.987c0 3.18 1.023 5.778 3.072 7.796 2.048 2.018 4.708 3.027 7.979 3.026 3.18 0 5.786-1 7.819-3.003 2.033-2.003 3.05-4.456 3.05-7.36 0-3.027-1.048-5.572-3.142-7.636-2.094-2.063-4.7-3.095-7.819-3.095-3.149 0-5.763 1.009-7.842 3.026-2.078 2.018-3.118 4.433-3.118 7.246zm-2.66 31.046c0 2.354.557 4.632 1.673 6.833 1.116 2.201 2.774 3.905 4.976 5.113s4.57 1.811 7.108 1.811c3.943 0 7.2-1.268 9.767-3.806s3.852-5.763 3.852-9.676c0-3.974-1.322-7.26-3.966-9.86-2.645-2.598-5.954-3.897-9.928-3.897-3.883 0-7.1 1.284-9.654 3.852-2.552 2.568-3.829 5.778-3.829 9.63z"
      transform="translate(0 -796.36)"
    />
  </svg>
)

export default SvgComponent
